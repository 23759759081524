<template>
    <div>

        <h5>
            גוגל
        </h5>

        <div>



            <!-- <div class="mb-3">
                חשבון גוגל מחובר:
                {{ googleUserData.email }}
            </div> -->

            <div class="row">
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">
                            יומן לסינכרון
                            (להזין כותבת
                            Gmail
                            )
                        </label>
                        <!-- <Multiselect v-model="googleChosenCalendar"
                                     label="summary"
                                     track-by="id"
                                     deselect-label=""
                                     placeholder="היומנים שלכם"
                                     selectLabel=""
                                     :options="googleCalendarList"></Multiselect> -->

                        <input class="form-control"
                               type="text"
                               v-model="googleChosenCalendarId">

                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">
                            סוג סנכרון
                        </label>
                        <Multiselect v-model="googleCalendarSyncDirection"
                                     deselect-label=" "
                                     label="label"
                                     track-by="value"
                                     placeholder="בחרו סוג סנכרון"
                                     selectLabel=""
                                     :options="syncOptionsList"></Multiselect>

                    </div>
                </div>

            </div>


            <div>

                <span style="color:red">
                    שימו לב!

                </span>
                <br>

                על מנת לסנכרן את היומנים יש לתת גישה ליומן לפי


                <span style="color:#ab8ce4;cursor:pointer"
                      @click="$refs.instructions.show()">
                    ההוראות</span>.





            </div>


            <br>


            <div>

                <label style="user-select: none;cursor:pointer"
                       @click="googleExportAsWholeDay = !googleExportAsWholeDay">


                    <span style="position:relative;top:1px;"
                          class="planup-checkbox planup-checkbox-purple"
                          :class="{ checked: googleExportAsWholeDay }">
                        <i class="fa fa-check"></i>
                    </span>


                    ייצוא אירועים כיום שלם
                    (יופיעו בראשית היום ולא בשעה ספציפית)
                </label>

            </div>



            <br>
            <br>



            <div class="pull-right">

                <!-- <button class="btn btn-danger"
                        @click="connectGoogleCalendar()">
                    <i class="fa-brands fa-google"></i>
                    &nbsp;
                    החלפת חשבון
                </button> -->

                <button class="btn btn-light"
                        @click="$refs.cleanupGoogleCalendar.show()">
                    &nbsp;
                    ניקוי ועדכון יומן
                </button>

                &nbsp;
                <button class="btn btn-primary"
                        @click="saveCalendarSync()">
                    &nbsp;
                    שמור
                </button>





            </div>


        </div>
        <!-- <div v-else>
            <button class="btn btn-danger"
                    @click="connectGoogleCalendar()">
                <i class="fa-brands fa-google"></i>
                &nbsp;
                חיבור חשבון גוגל
            </button>
        </div> -->



        <Modal title="ניקוי ועדכון יומן"
               ref="cleanupGoogleCalendar"
               :hideFooter="true">

            <template v-slot:body>
                <div>
                    הפעולה הזו תוודא שיומן גוגל מותאם בדיוק לנתונים שמוזנים במערכת.
                </div>

                <br>

                <div style="width:80%;margin:0 auto;">

                    <Multiselect label="label"
                                 selectedLabel=""
                                 deselect-label=""
                                 placeholder=""
                                 selectLabel=""
                                 :allow-empty="false"
                                 :showNoOptions="false"
                                 track-by="value"
                                 v-model="chosenCleanupCalendar"
                                 :options="cleanupCalendarList">

                    </Multiselect>
                </div>

                <br>
                <br>

                <div class="pull-right">

                    <button class="btn btn-light"
                            @click="$refs.cleanupGoogleCalendar.hide()">
                        ביטול
                    </button>

                    &nbsp;

                    <button class="btn btn-primary"
                            @click="cleanupGoogleCalendar()">
                        בצע
                    </button>


                </div>



            </template>

        </Modal>






        <Modal title="הוראות לביצוע סנכרון יומן גוגל"
               ref="instructions"
               :hideFooter="true">
            <template v-slot:body>


                <div>

                    עבור כל יומן גוגל יש לבצע את הפעולות הבאות:

                    <br>
                    <br>

                    <ol>
                        <li>
                            היכסנו ליומן גוגל
                            <br>
                            <br>
                        </li>
                        <li>
                            לחצו על כפתור 3 נקודות ליד היומן הרצוי
                            <br>
                            <br>
                            <img src="https://planup-il.s3.eu-central-1.amazonaws.com/google_sync_1.png"
                                 style="width:300px; margin: 0 auto;display: block">
                            <br>
                            <br>
                        </li>
                        <li>
                            בחרו "הגדרות ושיתוף"
                            <br>
                            <br>
                            <img src="https://planup-il.s3.eu-central-1.amazonaws.com/google_sync_2.png"
                                 style="width:300px; margin: 0 auto;display: block">
                            <br>
                            <br>
                        </li>
                        <li>
                            מצאו את הכותרת "שיתוף עם אנשים ספציפיים או קבוצות ספציפיות" ולחצו על הכפתור "הוספת אנשים
                            וקבוצות"
                            <br>
                            <br>
                            <img src="https://planup-il.s3.eu-central-1.amazonaws.com/google_sync_3.png"
                                 style="width:300px; margin: 0 auto;display: block">
                            <br>
                            <br>
                        </li>
                        <li>
                            הזינו את כתובת המייל הבאה:

                            <br>
                            <span style="font-weight:bold;">
                                planup@planup-397905.iam.gserviceaccount.com
                            </span>
                            <button class="btn btn-primary btn-xs"
                                    @click="copyServiceAccount">



                                <span v-if="copiedServiceAccount">

                                    <i class="fa-solid fa-check"></i>
                                    הועתק
                                </span>
                                <span v-else>
                                    העתק
                                </span>







                            </button>

                            <br>
                            <br>
                            <img src="https://planup-il.s3.eu-central-1.amazonaws.com/google_sync_4.png"
                                 style="width:300px; margin: 0 auto;display: block">
                            <br>
                            <br>
                        </li>
                        <li>

                            תחת הרשאות בחרו באפשרות

                            "לשנות אירועים"

                            <br>
                            <br>
                            <img src="https://planup-il.s3.eu-central-1.amazonaws.com/google_sync_5.png"
                                 style="width:300px; margin: 0 auto;display: block">
                            <br>
                            <br>
                        </li>
                        <li>
                            לחצו על שליחה על מנת לשמור
                        </li>
                    </ol>

                </div>

            </template>
        </Modal>

    </div>
</template>
<script>

import Multiselect from 'vue-multiselect';
import Config from '../../Config'
import axiosV2, { apiPost } from '../../core/http/axios_api_v2'



export default {
    name: 'GoogleData',
    data() {

        return {
            chosenCleanupCalendar: null,
            cleanupCalendarList: [],
            copiedServiceAccount: false,
            syncOptionsList: [

                {
                    label: 'ללא סנכרון',
                    value: 'none'
                },
                {
                    label: 'מפלנאפ לגוגל בלבד',
                    value: 'planup2google'
                },
                {
                    label: 'מגוגל לפלנאפ בלבד',
                    value: 'google2planup'
                },
                {
                    label: 'סנכרון דו כיווני',
                    value: 'both'
                }],
            googleCalendarSyncDirection: '',
            googleChosenCalendarId: null,
            googleExportAsWholeDay: false,
            // googleUserData: null,
            // googleCalendarList: []
        };
    },
    mounted() {
        // this.handleAccessTokenFlow();
        this.getGoogleData();
    },
    methods: {

        copyServiceAccount() {


            this.$window.navigator.clipboard.writeText('planup@planup-397905.iam.gserviceaccount.com').then(() => {
                this.copiedServiceAccount = true;
                setTimeout(() => {
                    this.copiedServiceAccount = false;
                }, 1000);
            });
        },

        saveCalendarSync() {


            let calendarSyncParams = {
                googleChosenCalendarId: this.googleChosenCalendarId,
                googleCalendarSyncDirection: this.googleCalendarSyncDirection.value,
                googleExportAsWholeDay: this.googleExportAsWholeDay
            }



            this.$swal({
                title: 'האם לבצע סנכרון ראשוני עכשיו?',
                text: 'שימו לב - אם החשבונות כבר מסונכרנים, עלולים להיווצר כפילויות.',
                confirmButtonText: 'סנכרן עכשיו',
                cancelButtonText: 'שמור הגדרות בלבד',
                confirmButtonColor: '#ab8ce4',
                showCancelButton: true,

            }).then(result => {

                calendarSyncParams.syncNow = true;

                apiPost('google-api/saveGoogleSyncOptions', calendarSyncParams)

            }).catch(error => {

                apiPost('google-api/saveGoogleSyncOptions', calendarSyncParams)


            })




        },


        cleanupGoogleCalendar(){
            apiPost(`google-mega-sync?crewMemberId=${this.chosenCleanupCalendar.value}`);
            this.$refs.cleanupGoogleCalendar.hide();
        },

        openSyncDirectionModal() {
            this.$refs.syncDirectionModal.show();
        },
        // handleAccessTokenFlow() {
        //     if (this.$store.state._alertConnectGoogleSuccess) {
        //         this.$swal({
        //             type: 'success',
        //             title: 'חשבון גוגל מחובר בהצלחה!',
        //             confirmButtonText: 'המשך',
        //             confirmButtonColor: '#ab8ce4'
        //         });
        //         delete this.$store.state._alertConnectGoogleSuccess;
        //         return;
        //     }
        //     let accessTokenMatch = window.location.href.match(/&access_token=(.*?)&/);
        //     if (!accessTokenMatch)
        //         return;
        //     axios.post('google-api/saveGoogleParams', { accessToken: accessTokenMatch[1] }).then((response) => {
        //         this.$router.push('/settings?activeTab=orginizationSettings_googleTab');
        //         this.$store.state._alertConnectGoogleSuccess = 1;
        //     });
        // },
        getGoogleData() {

            let that = this;
            axiosV2('google-api/getGoogleData').then(response => {
                // this.googleUserData = response.data?.googleUserData;
                // this.googleCalendarList = response.data?.googleCalendarList ? response.data?.googleCalendarList : [] ;
                // this.googleChosenCalendar = this.googleCalendarList.find(c => c.id == response.data.googleChosenCalendarId)

                this.googleChosenCalendarId = response.data?.googleChosenCalendarId;
                this.googleCalendarSyncDirection = response.data?.googleCalendarSyncDirection ? that.syncOptionsList.find(direction => direction.value == response.data?.googleCalendarSyncDirection) : that.syncOptionsList[0];
                this.googleExportAsWholeDay = response.data?.googleExportAsWholeDay;

                this.cleanupCalendarList = this.$store.state.crewMembers.filter(cm => cm.email).map(cm => { return { value: cm.id, label: `${cm.name} (${cm.email})` } });
                this.cleanupCalendarList.unshift({ value: 0, label: `יומן ראשי (${this.googleChosenCalendarId})` });

            });
        },
        // connectGoogleCalendar() {


        //     console.log('Redirect URI:');
        //     console.log(`${Config.SETTINGS.APP_URL}settings?activeTab=orginizationSettings_googleTab`);

        //     // Google's OAuth 2.0 endpoint for requesting an access token
        //     var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
        //     // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        //     var form = document.createElement('form');
        //     form.setAttribute('method', 'GET'); // Send as a GET request.
        //     form.setAttribute('action', oauth2Endpoint);
        //     // Parameters to pass to OAuth 2.0 endpoint.
        //     var params = {
        //         'client_id': '340965126822-psnjm1h1h26c5lgcreik4vjg39seepoo.apps.googleusercontent.com',
        //         'redirect_uri': `${Config.SETTINGS.APP_URL}settings?activeTab=orginizationSettings_googleTab`,
        //         'response_type': 'token',
        //         'scope': 'https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/userinfo.profile  https://www.googleapis.com/auth/calendar.calendarlist  https://www.googleapis.com/auth/calendar.events.readonly  https://www.googleapis.com/auth/calendar.events.owned',
        //         'include_granted_scopes': 'true',
        //         'state': 'pass-through value'
        //     };
        //     // Add form parameters as hidden input values.
        //     for (var p in params) {
        //         var input = document.createElement('input');
        //         input.setAttribute('type', 'hidden');
        //         input.setAttribute('name', p);
        //         input.setAttribute('value', params[p]);
        //         form.appendChild(input);
        //     }
        //     // Add form to page and submit it to open the OAuth 2.0 endpoint.
        //     document.body.appendChild(form);
        //     form.submit();
        // }
    },
    components: { Multiselect }
}
</script>