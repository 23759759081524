<template>
  <div>
    <PageHeader :title="$t('settings')"
                :breadcrumbs="[
                  {
                    route: null,
                    name: $t('menu_settings'),
                  },
                ]">
    </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <!-- NAV TABS -->

              <div class="border-tab nav nav-tabs">
                <li v-for="tab in tabs"
                    class="nav-item">
                  <a class="nav-link"
                     @click="activeTab = tab"
                     :class="{ active: activeTab == tab }">
                    {{ $t(tab) }}
                  </a>
                </li>
              </div>

              <!-- TABS CONTENT -->

              <div class="row">
                <div class="tab-content">
                  <!-- GOOGLE SYNC -->

                  <div class="tab-pane"
                       :class="{ active: activeTab == 'orginizationSettings_googleTab' }">
                    <GoogleSettings></GoogleSettings>

                    <br>
                    <br>

                    <WhatsappWeb :suggestedPhone="$store.state.businessSettings.phone"></WhatsappWeb>


                    <br>
                    <br>


                    <h5>
                      פייסבוק
                    </h5>




                    <button class="btn btn-primary"
                            @click="getFacebookPages">
                      חיבור חשבון פייסבוק
                    </button>




                    <br>
                    <br>




                    <h5>
                      חשבונית ירוקה
                    </h5>



                    <div class="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label class="form-label">
                            מפתח API
                          </label>
                          <input class="form-control"
                                 v-model="$store.state.businessSettings.heshbonitYerukaApiKey">

                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label class="form-label">
                            סוד
                          </label>
                          <input class="form-control"
                                 v-model="$store.state.businessSettings.heshbonitYerukaApiSecret">

                        </div>
                      </div>

                    </div>




                    <div class="pull-right">

                      <button class="btn btn-primary"
                              @click="saveHeshbonitYeruka">
                        שמור
                      </button>


                    </div>



                  </div>







                  <!-- BILLING TAB -->

                  <div role="tabpanel"
                       class="tab-pane"
                       :class="{ active: activeTab == 'orginizationSettings_billingTab' }">
                    <div class="row">
                      <div class="col-md-12">

                        <h3>{{ $t('billing') }}</h3>
                        <FormInput label="Billing key"
                                   v-model="userSettings.attrs.billing_api_key"></FormInput>
                        <FormInput label="Billing secret"
                                   v-model="userSettings.attrs.billing_api_secret"></FormInput>
                        <FormInput label="Brand UID"
                                   v-model="userSettings.attrs.billing_api_brand_uuid"></FormInput>
                        <hr>
                        <h3>{{ $t('invoice') }}</h3>
                        <FormInput type="number"
                                   label="מספר עסקה נוכחי"
                                   v-model="userSettings.orderNumbering"
                                   placeholder="0000001"
                                   required></FormInput>

                      </div>
                    </div>

                  </div>







                  <div role="tabpanel"
                       class="tab-pane"
                       :class="{ active: activeTab == 'orginizationSettings_reshutHamisimTab' }">


                    <div class="row">
                      <h5>
                        מספרי רישום
                      </h5>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">מספר ח.פ</label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.hetPey"
                                 required />
                          <span class="formErrorText">
                            {{ errors.hetPey }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> מספר עוסק מורשה </label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.numOsekMurshe"
                                 required />
                          <span class="formErrorText">
                            {{ errors.numOsekMurshe }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> מספר תיק ניכויים </label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.numTikNihuim"
                                 required />
                          <span class="formErrorText">
                            {{ errors.numTikNihuim }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> מספר חברה ברשם החברות </label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.numHevraRashamHevrot"
                                 required />
                          <span class="formErrorText">
                            {{ errors.numHevraRashamHevrot }}
                          </span>
                        </div>
                      </div>


                    </div>


                    <br>
                    <br>


                    <div class="row">
                      <h5>
                        חשבונות בנק
                      </h5>


                      <div v-for="bankAccount in $store.state.businessSettings.bankAccounts"
                           class=row>

                        <div class="col-md-3">
                          <div class="mb-3">
                            <label class="form-label">
                              בנק
                            </label>
                            <Multiselect :options="$store.state.banks"
                                         label="name"
                                         value="value"
                                         v-model="bankAccount.bank"
                                         selectLabel=""
                                         placeholder="">

                            </Multiselect>
                            <span class="formErrorText"
                                  v-if="bankAccount.errors">
                              {{ bankAccount.errors.bank }}
                            </span>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="mb-3">
                            <label class="form-label">
                              סניף
                            </label>
                            <input class="form-control"
                                   type="number"
                                   v-model="bankAccount.snif">
                            <span class="formErrorText"
                                  v-if="bankAccount.errors">
                              {{ bankAccount.errors.snif }}
                            </span>

                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="mb-3">
                            <label class="form-label">
                              חשבון
                            </label>
                            <input class="form-control"
                                   type="number"
                                   v-model="bankAccount.account">
                            <span class="formErrorText"
                                  v-if="bankAccount.errors">
                              {{ bankAccount.errors.account }}
                            </span>

                          </div>
                        </div>


                      </div>


                      <div style="">

                        <span @click="$store.state.businessSettings.bankAccounts.push({})"
                              style="color:#8d63da;cursor:pointer;font-size:25px;">
                          <i class="fa-solid fa-plus"></i>
                        </span>

                      </div>


                    </div>







                    <br>
                    <br>

                    <div class="row">

                      <h5>
                        ספרור מסמכים
                      </h5>



                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת ספרור לקוחות </label>
                          <input v-model="$store.state.businessSettings.taxDocument_startCount_lakuah
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_lakuah }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת ספרור הזמנות </label>
                          <input v-model="$store.state.businessSettings.taxDocument_startCount_hazmana"
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_hazmana }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת ספרור חשבוניות מס </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_heshbonitMas
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_heshbonitMas }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">
                            תחילת סיפרור חשבוניות מס קבלה
                          </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_heshbonitMasKabala
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_heshbonitMasKabala }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור חשבוניות זיכוי </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_heshbonitZikui
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_heshbonitZikui }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור קבלות </label>
                          <input v-model="$store.state.businessSettings.taxDocument_startCount_kabala
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_kabala }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור חשבונות עסקה </label>
                          <input v-model="$store.state.businessSettings.taxDocument_startCount_heshbonIska
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_heshbonIska }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור הצעות מחיר </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_hatsaatMehir
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_hatsaatMehir }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור תעודות משלוח </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_teudatMishloah
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_teudatMishloah }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> תחילת סיפרור הזמנת רכש </label>
                          <input v-model="$store.state.businessSettings
                            .taxDocument_startCount_hazmanatRehesh
                            "
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.taxDocument_startCount_hazmanatRehesh }}
                          </span>
                        </div>
                      </div>


                    </div>


                    <br>
                    <br>

                    <div class="row">

                      <h5>
                        כללי
                      </h5>


                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">
                            סוג הנהלת חשבונות
                          </label>

                          <Multiselect v-model="selectedTypeOfAccounting"
                                       deselect-label=""
                                       placeholder=""
                                       selectLabel=""
                                       track-by="value"
                                       label="name"
                                       :showNoResults="false"
                                       selectedLabel=""
                                       :options="typesOfAccounting"
                                       :taggable="false">

                          </Multiselect>


                        </div>
                      </div>

                    </div>

                    <button @click="saveMisimTab()"
                            class="btn btn-primary pull-right">{{ $t("save") }}</button>





                  </div>

                  <!-- GENERAL TAB -->

                  <div role="tabpanel"
                       class="tab-pane"
                       :class="{ active: activeTab == 'orginizationSettings_generalTab' }">
                    <div class="row">
                      <div>


                        <img :src="$store.state.logoImageUrl"
                             onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'"
                             style="max-width:80px;max-height:80px;border-radius:6px;">


                        <span style="color: #ab8ce4; cursor: pointer"
                              @click="$refs.uploadLogoFileInput.click()">
                          עדכן לוגו לעסק
                        </span>


                        &nbsp;
                        &nbsp;



                        <img style="max-width:80px;max-height:80px;border-radius:6px;"
                             onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'"
                             :src="`data:${$store.state.businessSettings.signature64mimetype};base64, ${$store.state.businessSettings.signature64}`">


                        <span style="color: #ab8ce4; cursor: pointer"
                              @click="$refs.uploadSignatureFileInput.click()">
                          העלה חתימה סרוקה
                        </span>


                        <br />
                        <br />

                        <input ref="uploadLogoFileInput"
                               type="file"
                               style="display: none"
                               @change="uploadLogo" />
                        <br />

                        <input ref="uploadSignatureFileInput"
                               type="file"
                               style="display: none"
                               @change="uploadSignature" />
                      </div>


                      <h5>כללי</h5>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label class="form-label">שם העסק</label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.bizName" />
                          <span class="formErrorText">
                            {{ errors.bizName }}
                          </span>





                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label class="form-label">טלפון</label>
                          <input type="text"
                                 class="form-control"
                                 v-model="$store.state.businessSettings.phone" />
                          <span class="formErrorText">
                            {{ errors.phone }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label class="form-label">

                            תיבת דואר

                            planup.co.il@
                          </label>
                          <input type="text"
                                 class="form-control"
                                 v-model="$store.state.businessSettings.nameForPlanupEmail" />
                          <span class="formErrorText">
                            {{ errors.nameForPlanupEmail }}
                          </span>
                        </div>
                      </div>




                      <!-- <label style="user-select: none; cursor: pointer"
                             @click="
                               $store.state.businessSettings.hasSnifim = !$store.state
                                 .businessSettings.hasSnifim
                               ">
                        <span style="position: relative; top: 1px"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{
                                checked: $store.state.businessSettings.hasSnifim,
                              }">
                          <i class="fa fa-check"></i>
                        </span>

                        לעסק יש סניפים
                      </label> -->

                      <br>
                      <br>
                    </div>

                    <br>

                    <div class="row">
                      <h5>
                        כתובת
                      </h5>


                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> רחוב </label>
                          <input class="form-control"
                                 type="text"
                                 v-model="$store.state.businessSettings.address_street" />
                          <span class="formErrorText">
                            {{ errors.address_street }}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> מספר בית </label>
                          <input v-model="$store.state.businessSettings.address_number"
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.address_number }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> עיר</label>
                          <input v-model="$store.state.businessSettings.address_city"
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.address_city }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label"> מיקוד</label>
                          <input v-model="$store.state.businessSettings.address_mikud"
                                 class="form-control" />
                          <span class="formErrorText">
                            {{ errors.address_mikud }}
                          </span>
                        </div>
                      </div>
                      <br>
                      <br>
                    </div>



                    <br>
                    <div class="row">

                      <h5>
                        שעות פעילות לתצוגה ביומן
                      </h5>

                      <div class="col-md-3">
                        <div>
                          <label class="form-label">
                            תחילת יום עבודה
                          </label>

                          <Multiselect v-model="$store.state.businessSettings.workDayStart"
                                       deselect-label=""
                                       placeholder=""
                                       selectLabel=""
                                       :showNoResults="false"
                                       selectedLabel=""
                                       :options="workDayHourList"
                                       :taggable="false">

                          </Multiselect>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div>
                          <label class="form-label">
                            סיום יום עבודה
                          </label>

                          <Multiselect v-model="$store.state.businessSettings.workDayEnd"
                                       deselect-label=""
                                       placeholder=""
                                       selectLabel=""
                                       :showNoResults="false"
                                       selectedLabel=""
                                       :options="workDayHourList"
                                       :taggable="false">

                          </Multiselect>
                        </div>
                      </div>



                      <div class="col-md-3">
                        <div>
                          <label class="form-label">
                            שעה ברירת מחדל לתחילת אירועים
                          </label>

                          <Multiselect v-model="$store.state.businessSettings.defaultEventTime"
                                       deselect-label=""
                                       placeholder=""
                                       selectLabel=""
                                       :showNoResults="false"
                                       selectedLabel=""
                                       :options="$store.state.timesArray"
                                       :taggable="false">

                          </Multiselect>
                        </div>
                      </div>





                    </div>

                    <br>

                    <div class="row">

                      <h5>
                        שונות
                      </h5>

                      <div class="col-md-3">
                          <label style="user-select: none;cursor:pointer"
                                 @click="$store.state.businessSettings.validateLeadSource  = !$store.state.businessSettings.validateLeadSource ">


                            <span style="position:relative;top:1px;"
                                  class="planup-checkbox planup-checkbox-purple"
                                  :class="{ checked: $store.state.businessSettings.validateLeadSource  }">
                              <i class="fa fa-check"></i>
                            </span>

                            חובה להזין מקור ליד
                          </label>
                      </div>


                    </div>

                    <br>
                    <br>
                    <button @click="saveGeneralTab()"
                            class="btn btn-primary pull-right">{{ $t("save") }}</button>




                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





    <Modal ref="welcomeToPlanup"
           :hideFooter="true"
           :hideHeader="true"
           :noClose="true">


      <template v-slot:body>


        <div style="text-align: center">


          <br>



          <img src="/static/planup.png"
               style="height:40px;">

          <br>
          <br>



          <h1 style="font-size:40px;">
            ברוכים הבאים
          </h1>

          <br>


          <div style="color:rgb(119, 119, 119);font-size:18px">
            על מנת שנוכל להתחיל לעבוד ביחד, אנחנו צריכים

            <br>
            מספר נתונים לגבי העסק שלכם:
          </div>


          <br>
          <br>

          <div style="color:rgb(119, 119, 119);font-size:18px;width:70%;text-align: right;margin: 0 auto;">


            <div style="display:inline-block;width:30px;color:#ab8ce4;font-size:25px;">
              <i class="fa-solid fa-briefcase"
                 style="position:relative;top:5px;"></i>
            </div>
            פרטים כלליים של העסק


            <br>




            <div style="display:inline-block;width:30px;color:#00c292;font-size:25px;">
              <i class="fa-solid fa-file-lines"
                 style="position:relative;top:5px;"></i>
            </div>
            נתוני חשבונאות ורשות המיסים



            <br>



            <div style="display:inline-block;width:30px;color:#26c6da;font-size:25px;">
              <i class="fa-solid fa-list-ol"
                 style="position:relative;top:5px;"></i>
            </div>
            מספרי ספרור מסמכים חשבונאיים


            <br>
            <br>


            <label style="user-select: none;cursor:pointer;font-size:16px;"
                   @click="$store.state.businessSettings.stopDisplayingWelcomePopup = !$store.state.businessSettings.stopDisplayingWelcomePopup">


              <span style="position:relative;top:1px;"
                    class="planup-checkbox planup-checkbox-purple"
                    :class="{ checked: $store.state.businessSettings.stopDisplayingWelcomePopup }">
                <i class="fa fa-check"></i>
              </span>


              אל תציג יותר אם גם חסרים פרטים
            </label>


          </div>



          <br>




          <button style="font-size:18px"
                  @click="closeWelcomePopup()"
                  class="btn btn-primary">
            יאללה לעבודה
          </button>


          <br>
          <br>


        </div>



      </template>




    </Modal>



  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { apiPost } from "../../core/http/axios_api_v2";
import GoogleSettings from "./GoogleSettings.vue";
import WhatsappWeb from "./WhatsappWeb.vue";

export default {
  name: "Settings",

  data() {

    let workDayHourList = [];


    for (let i = 0; i < 24; i++) {
      workDayHourList.push(`${i}:00`);
    }


    return {
      selectedTypeOfAccounting: null,
      typesOfAccounting: [
        { value: 0, name: "לא רלוונטי" },
        { value: 1, name: "חד צידית" },
        { value: 2, name: "כפולה" }
      ],

      workDayHourList,
      errors: {},
      tabs: [
        "orginizationSettings_generalTab",
        "orginizationSettings_reshutHamisimTab",
        // "orginizationSettings_billingTab",
        "orginizationSettings_googleTab",
      ],
      activeTab: "orginizationSettings_generalTab",
      googleUserData: null,
      userSettings: { attrs: {} },
    };
  },
  methods: {


    getFacebookPages() {

      window.FB.login(function (response) {

        if (!response.status == 'connected' || !response.authResponse.accessToken) {
          alert('שגיאה');
          return;
        }

        axios.post('facebook/pages', { token: response.authResponse.accessToken })

      }, { scope: 'email,pages_read_engagement' });

    },

    closeWelcomePopup() {

      this.$refs.welcomeToPlanup.hide();
      if (this.$store.state.businessSettings.stopDisplayingWelcomePopup) {
        apiPost('business-settings', { stopDisplayingWelcomePopup: true })
      }



    },

    saveHeshbonitYeruka() {

      let saveData = {
        heshbonitYerukaApiKey: this.$store.state.businessSettings.heshbonitYerukaApiKey,
        heshbonitYerukaApiSecret: this.$store.state.businessSettings.heshbonitYerukaApiSecret,
      }

      apiPost('business-settings', saveData);
    },





    validateGeneralTab() {

      let errors = {};


      if (!this.$store.state.businessSettings.bizName)
        errors.bizName = "שדה חובה";


      if (!this.$store.state.businessSettings.phone || !this.$store.state.businessSettings.phone.toString().match(/^\d{9,10}$/) || this.$store.state.businessSettings.phone[0] != '0')
        errors.phone = "מספר טלפון לא תקין";


      // if (!this.$store.state.businessSettings.address_street)
      //   errors.address_street = "שדה חובה";

      // if (!this.$store.state.businessSettings.address_number)
      //   errors.address_number = "שדה חובה";

      // if (!this.$store.state.businessSettings.address_city)
      //   errors.address_city = "שדה חובה";

      if (this.$store.state.businessSettings.address_mikud && !this.$store.state.businessSettings.address_mikud.toString().match(/^\d{7}$/))
        errors.address_mikud = "נא להזין מיקוד תקין";


      if (!this.$store.state.businessSettings?.nameForPlanupEmail?.length) {

        errors.nameForPlanupEmail = "שדה חובה";

      } else if (!this.$store.state.businessSettings.nameForPlanupEmail.match(/^[a-z0-9]+$/i)) {

        errors.nameForPlanupEmail = 'אותיות באנגלית ומספרים בלבד';

      } else if (['office', 'info', 'admin', 'support'].includes(this.$store.state.businessSettings.nameForPlanupEmail)) {

        errors.nameForPlanupEmail = 'לא ניתן לבחור בשם בזה';

      }

      this.$set(this, "errors", errors);

      //this checks if there is at least one field with an error
      let valid = !Object.values(this.errors).some(value => value);

      if (!valid)
        planup.$globals.notifyFormError();

      return valid;
    },

    saveGeneralTab() {


      if (!this.validateGeneralTab())
        return;

      let saveData = {
        bizName: this.$store.state.businessSettings.bizName,
        phone: this.$store.state.businessSettings.phone,
        hasSnifim: this.$store.state.businessSettings.hasSnifim,
        address_street: this.$store.state.businessSettings.address_street,
        address_number: this.$store.state.businessSettings.address_number,
        address_city: this.$store.state.businessSettings.address_city,
        address_mikud: this.$store.state.businessSettings.address_mikud,
        nameForPlanupEmail: this.$store.state.businessSettings.nameForPlanupEmail,
        workDayStart: this.$store.state.businessSettings.workDayStart,
        workDayEnd: this.$store.state.businessSettings.workDayEnd,
        defaultEventTime: this.$store.state.businessSettings.defaultEventTime,
        validateLeadSource: this.$store.state.businessSettings.validateLeadSource 
      }



      apiPost('business-settings', saveData);
    },


    validateMisimTab() {

      let errors = {};
      let valid = true;



      if (!this.$store.state.businessSettings.hetPey && !this.$store.state.businessSettings.numOsekMurshe) {
        errors.hetPey = 'נא להזין מספר ח.פ או עוסק מורשה תקין'
        errors.numOsekMurshe = 'נא להזין מספר ח.פ או עוסק מורשה תקין'

      } else {

        if (this.$store.state.businessSettings.hetPey && !this.$store.state.businessSettings.hetPey.toString().match(/^\d{9}$/)) {
          errors.hetPey = 'נא להזין מספר ח.פ תקין'
        }

        if (this.$store.state.businessSettings.numOsekMurshe && !this.$store.state.businessSettings.numOsekMurshe.toString().match(/^\d{9}$/)) {
          errors.numOsekMurshe = 'נא להזין מספר עוסק מורשה תקין'
        }

      }





      if (this.$store.state.businessSettings.numTikNihuim && !this.$store.state.businessSettings.numTikNihuim.toString().match(/^\d{9}$/))
        errors.numTikNihuim = 'נא להזין מספר תיק ניכויים תקין'

      if (this.$store.state.businessSettings.numHevraRashamHevrot && !this.$store.state.businessSettings.numHevraRashamHevrot.toString().match(/^\d{9}$/))
        errors.numHevraRashamHevrot = 'נא להזין מספר חברה תקין'


      this.$store.state.businessSettings.bankAccounts.forEach(bankAccount => {

        let bankAccountErrors = {};


        if (!bankAccount.bank)
          bankAccountErrors.bank = 'נא לבחור בנק';

        if (!bankAccount.snif)
          bankAccountErrors.snif = 'נא להזין סניף';

        if (!bankAccount.account)
          bankAccountErrors.account = 'נא להזין חשבון';

        this.$set(bankAccount, "errors", bankAccountErrors);

        valid &= !Object.values(bankAccountErrors).some(value => value);


      })


      if (!this.$store.state.businessSettings.taxDocument_startCount_lakuah || parseInt(this.$store.state.businessSettings.taxDocument_startCount_lakuah) <= 0) {
        errors.taxDocument_startCount_lakuah = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_heshbonitMas || parseInt(this.$store.state.businessSettings.taxDocument_startCount_heshbonitMas) <= 0) {
        errors.taxDocument_startCount_heshbonitMas = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_heshbonitMasKabala || parseInt(this.$store.state.businessSettings.taxDocument_startCount_heshbonitMasKabala) <= 0) {
        errors.taxDocument_startCount_heshbonitMasKabala = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_heshbonitZikui || parseInt(this.$store.state.businessSettings.taxDocument_startCount_heshbonitZikui) <= 0) {
        errors.taxDocument_startCount_heshbonitZikui = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_kabala || parseInt(this.$store.state.businessSettings.taxDocument_startCount_kabala) <= 0) {
        errors.taxDocument_startCount_kabala = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_heshbonIska || parseInt(this.$store.state.businessSettings.taxDocument_startCount_heshbonIska) <= 0) {
        errors.taxDocument_startCount_heshbonIska = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_hatsaatMehir || parseInt(this.$store.state.businessSettings.taxDocument_startCount_hatsaatMehir) <= 0) {
        errors.taxDocument_startCount_hatsaatMehir = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_teudatMishloah || parseInt(this.$store.state.businessSettings.taxDocument_startCount_teudatMishloah) <= 0) {
        errors.taxDocument_startCount_teudatMishloah = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_hazmana || parseInt(this.$store.state.businessSettings.taxDocument_startCount_hazmana) <= 0) {
        errors.taxDocument_startCount_hazmana = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_heshbonIska || parseInt(this.$store.state.businessSettings.taxDocument_startCount_heshbonIska) <= 0) {
        errors.taxDocument_startCount_heshbonIska = 'נא להזין מספר תקין';
      }


      if (!this.$store.state.businessSettings.taxDocument_startCount_hazmanatRehesh || parseInt(this.$store.state.businessSettings.taxDocument_startCount_hazmanatRehesh) <= 0) {
        errors.taxDocument_startCount_hazmanatRehesh = 'נא להזין מספר תקין';
      }




      this.$set(this, "errors", errors);

      //this checks if there is at least one field with an error
      valid &= !Object.values(this.errors).some(value => value);

      if (!valid)
        planup.$globals.notifyFormError();

      return valid;
    },


    saveMisimTab() {


      if (!this.validateMisimTab())
        return;


      let saveData = {
        hetPey: this.$store.state.businessSettings.hetPey,
        numOsekMurshe: this.$store.state.businessSettings.numOsekMurshe,
        numTikNihuim: this.$store.state.businessSettings.numTikNihuim,
        numHevraRashamHevrot: this.$store.state.businessSettings.numHevraRashamHevrot,
        taxDocument_startCount_lakuah: this.$store.state.businessSettings.taxDocument_startCount_lakuah,
        taxDocument_startCount_heshbonitMas: this.$store.state.businessSettings.taxDocument_startCount_heshbonitMas,
        taxDocument_startCount_heshbonitMasKabala: this.$store.state.businessSettings.taxDocument_startCount_heshbonitMasKabala,
        taxDocument_startCount_heshbonitZikui: this.$store.state.businessSettings.taxDocument_startCount_heshbonitZikui,
        taxDocument_startCount_kabala: this.$store.state.businessSettings.taxDocument_startCount_kabala,
        taxDocument_startCount_heshbonIska: this.$store.state.businessSettings.taxDocument_startCount_heshbonIska,
        taxDocument_startCount_hatsaatMehir: this.$store.state.businessSettings.taxDocument_startCount_hatsaatMehir,
        taxDocument_startCount_teudatMishloah: this.$store.state.businessSettings.taxDocument_startCount_teudatMishloah,
        taxDocument_startCount_hazmana: this.$store.state.businessSettings.taxDocument_startCount_hazmana,
        taxDocument_startCount_heshbonIska: this.$store.state.businessSettings.taxDocument_startCount_heshbonIska,
        taxDocument_startCount_hazmanatRehesh: this.$store.state.businessSettings.taxDocument_startCount_hazmanatRehesh,
        bankAccounts: this.$store.state.businessSettings.bankAccounts,
        typeOfAccounting: this.selectedTypeOfAccounting.value
      }

      apiPost('business-settings', saveData);

    },

    uploadLogo() {
      var formData = new FormData();

      formData.append("file", this.$refs.uploadLogoFileInput.files[0]);

      apiPost("files/uploadLogo", formData).then(() => {
        this.$store.state.logoImageUrl += 7;
      });
    },

    uploadSignature() {
      var formData = new FormData();

      formData.append("file", this.$refs.uploadSignatureFileInput.files[0]);

      apiPost("files/uploadSignature", formData).then((data) => {
        this.$store.state.businessSettings.signature64 = data.signature64
        this.$store.state.businessSettings.signature64mimetype = data.signature64mimetype
      });
    },
  },
  components: {
    GoogleSettings,
    Multiselect,
    WhatsappWeb
  },

  mounted() {


    if (this.$router.currentRoute.query.activeTab)
      this.activeTab = this.$router.currentRoute.query.activeTab;





    if (!this.$store.state.businessSettings.hetPey && !this.$store.state.businessSettings.stopDisplayingWelcomePopup) {
      this.$refs.welcomeToPlanup.show();
    }


    this.selectedTypeOfAccounting = this.typesOfAccounting.find(type => type.value == +this.$store.state.businessSettings.typeOfAccounting);



  },
};
</script>
